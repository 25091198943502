import i18next from 'i18next'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {store} from '../index'
import {showAlert} from '../store/appGenerics'
import {ALERT_LEVELS, MATTERPORT_STATUSES, QUOTE_STATUSES, ROUTES} from './constants'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {useUserStore} from '../features/user/store'
//dayjs
dayjs.extend(LocalizedFormat)
dayjs.extend(advancedFormat)

export const localStorageManager = {
    set: (key, value) => localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value)),
    get: key => (localStorage.getItem(key) === undefined ? null : localStorage.getItem(key)),
    remove: key => localStorage.removeItem(key)
}

export const errorHandler = error => {
    if (error.response?.status === 401) {
        useUserStore.getState().resetUser('all')
    }
    error.response?.data?.message
        ? store.dispatch(
              showAlert({
                  message: i18next.t(`errors:${error.response?.data?.message}`),
                  level: ALERT_LEVELS.ERROR
              })
          )
        : store.dispatch(
              showAlert({
                  message: i18next.t('errors:default'),
                  level: ALERT_LEVELS.ERROR
              })
          )
}

export const retrieveValueForRs = (options, value, isMulti) => {
    if (value === null || value === '' || value === undefined) {
        return null
    } else {
        return isMulti
            ? options.filter(option => value?.includes(option.value.toString()))
            : options.find(option => option.value.toString() === value.toString())
    }
}

export const capitalize = string => (string ? string[0].toUpperCase() + string.substring(1) : '')

export const formatLocaleDate = (date, formatType = 'YYYY-MM-DD') =>
    date ? dayjs(date).locale(i18next.language).format(formatType) : '-'

export const calculateDateDifference = endDate => {
    const start = dayjs()
    const end = dayjs(endDate)

    return end.diff(start, 'day')
}

export const calculateHourDifference = startingTime => {
    const start = dayjs(startingTime)
    const now = dayjs()

    return now.diff(start, 'hour')
}

export const isPasteDate = date => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()

export const getMonthAndYear = date => dayjs(date).format('YYYY-MM')

export const parse = value => (typeof value === 'string' && value !== 'undefined' && !!value ? JSON.parse(value) : null)

export const getMetaAttribute = (meta, attributeName) => meta?.find(e => e.attribute === attributeName)?.value

/*necessary because of Safari redirect block*/
export const openLinkInNewTab = href => {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href
    }).click()
}

export const copyTextToClipboard = async text => {
    if (!text) return

    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text)
    } else {
        document.execCommand('copy', true, text)
    }
}

export const renderTableQuoteStatus = (status, theme) => {
    switch (status) {
        case QUOTE_STATUSES.SIGNED:
            return {label: 'statuses:signed', color: theme?.colors.green}
        case QUOTE_STATUSES.DECLINED:
            return {label: 'statuses:declined', color: theme?.colors.red}
        case QUOTE_STATUSES.EXPIRED:
            return {label: 'statuses:expired', color: theme?.colors.darkGrey}
        case QUOTE_STATUSES.RENEWED:
            return {label: 'statuses:renewed', color: theme?.colors.yellow}
        case QUOTE_STATUSES.MARKET_CLOSED:
            return {label: 'statuses:market_closed', color: theme?.colors.cyan}
        case QUOTE_STATUSES.CANCELLED:
            return {label: 'statuses:cancelled', color: theme?.colors.darkGrey}
        case QUOTE_STATUSES.COMPLETED:
            return {label: 'commons:completed', color: theme?.colors.green}
        case QUOTE_STATUSES.SENT:
            return {label: 'statuses:sent', color: theme?.colors.cyan}
        default:
            return {label: status, color: theme?.colors.darkGrey}
    }
}

export const renderTableMatterportStatus = status => {
    switch (status) {
        case MATTERPORT_STATUSES.READY_QA:
            return {label: 'admin:ready_for_qa', color: '#428BFF'}
        case MATTERPORT_STATUSES.PENDING_APPROVAL:
            return {label: 'admin:pending_host_approval', color: '#717171'}
        case MATTERPORT_STATUSES.REVISION:
            return {label: 'admin:matterport_revision', color: '#C13515'}
        case MATTERPORT_STATUSES.READY_PUBLISH:
            return {label: 'admin:ready_for_publish', color: '#006482'}
        case MATTERPORT_STATUSES.COMPLETED:
            return {label: 'commons:completed', color: '#008A05'}
        default:
            return {label: 'admin:upload_to_mediabox', color: '#ED9462'}
    }
}

export const getServiceName = (entity_type, category, media) => {
    // ${capitalize(shoot_service?.name)} temporary removed. It was placed after entity_type
    const categoryName = function() {
        if (category?.name === 'Japan') return 'Private Room'
        if (category?.name === 'ERF-H1-2024') return 'Production'
        if(["Services Catering",
          "Services Hair Styling",
            "Services Make Up",
            "Services Nails",
            "Services Personal Trainer",
            "Services Chef",
            "Services Prepared Meals"].includes(category?.name)) {
            return 'M13 Provider'
        }
        return category?.name
    }()
    
    return `
    ${capitalize(entity_type?.name)}
    ${capitalize(categoryName)}
    ${capitalize(media?.name === 'inspection' ? 'Walkthrough' : media?.name)}
  `
}

export const getDesiredShootDates = host_availability_dates => {
    if (!host_availability_dates || host_availability_dates.length === 0) {
        return '-'
    }
    const formattedDates = host_availability_dates
        .slice(0, 2) // Important: support only the first 2 dates
        .map(date => formatLocaleDate(date.starting_time, 'MMM D, YYYY'))
    return formattedDates.join(' - ')
}

export const openShootInNewTab = code => {
    const locationRef = window.location.origin
    return window.open(`${locationRef}${ROUTES.SINGLE_ASSIGNMENT_NO_ID}/${code}`, '_blank')
}

export const removeDoubleSlashFromPath = path => path.replace(/([^:])(\/\/+)/g, '$1/')

export const formatCurrency = (amount = 0, currency) =>
    new Intl.NumberFormat('en', {
        style: 'currency',
        currency
    }).format(amount)

export const formatTransactionAmount = (amount, amountUSD, currency, prefix = '') => {
    const isCurrencyUSD = !currency || currency === 'USD'
    return `${prefix}${formatCurrency(amount || amountUSD, currency ?? 'USD')}${
        isCurrencyUSD ? '' : `(${formatCurrency(amountUSD, 'USD')})`
    }`
}

//TODO: we use this in accordion because with dayjs the render is faster then the dayjs locale change
export const formatDateWithLocaleIntl = (locale, date, options) =>
    new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        ...options
    }).format(date)

export const formatFileSize = size => {
    if (!size) return '-'
    const bytes = size
    const units = ['B', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0) {
        return '0 B'
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    const fileSize = (bytes / Math.pow(1024, i)).toFixed(0)

    return `${fileSize} ${units[i]}`
}

export const clearNullishKeys = object =>
    Object.fromEntries(
        Object.entries(object).filter(([, value]) => value !== '' && value !== null && value !== undefined)
    )

export const positiveIntRegex = /^\d+$/
