import {Trans, useTranslation} from 'react-i18next'
import {Assignment, AssignmentUrlParams, Space} from 'src/features/assignment/types'
import {useState} from 'react'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams'
import {useGetSpaces} from 'src/features/assignment/services/useGetSpaces'
import {AxiosError} from 'axios'
import {ErrorBox} from 'src/components/error-box/ErrorBox'
import {AlertTriangleIcon} from 'src/components/icon'
import {Skeleton} from 'src/components/skeleton/Skeleton'
import {
    StyledAssignmentShootlist,
    StyledAssignmentShootlistItem,
    StyledAssignmentShootlistLink,
    StyledDashedDivider,
    StyledPhotosCounter
} from 'src/features/assignment/components/assignment-shootlist-card/style'
import {getAssignmentServiceType, isExperiencesOrServicesCategory, spaceKeyToLabel} from 'src/features/assignment/utils'
import {
    AssignmentCardSubtitle,
    AssignmentCardTitle,
    AssignmentCardWrapper
} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Checkbox} from 'src/components/checkbox/Checkbox'

export const LOCALSTORAGE_KEY_SHOOTLIST_CARD_PREFIX = 'shootlist-flags'

export const AssignmentShootlistCard = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(AssignmentUrlParams)
    const storedFlags = localStorage.getItem(`${LOCALSTORAGE_KEY_SHOOTLIST_CARD_PREFIX}-${assignment.id}`)

    const [currentFlags, setCurrentFlags] = useState(storedFlags ? JSON.parse(storedFlags) : [])

    const assignmentSpacesQuery = useGetSpaces(assignment.id, {
        enabled: urlParams.success
    })

    const onChangeShootlistFlag = (flag: Space['space']['key_name']) => {
        const updatedFlags = currentFlags.includes(flag)
            ? currentFlags.filter((parsedFlag: Space['space']['key_name']) => parsedFlag != flag)
            : currentFlags.concat(flag)

        localStorage.setItem(`${LOCALSTORAGE_KEY_SHOOTLIST_CARD_PREFIX}-${assignment.id}`, JSON.stringify(updatedFlags))
        setCurrentFlags(updatedFlags)
    }

    const shouldShowShootlistCard = !isExperiencesOrServicesCategory(assignment)

    if (assignmentSpacesQuery.isLoading) {
        return (
            <AssignmentCardWrapper>
                <Skeleton height={28} />
                {Array.from({length: 10}).map((_, index) => (
                    <Skeleton key={index} />
                ))}
            </AssignmentCardWrapper>
        )
    }

    if (
        urlParams.error ||
        (assignmentSpacesQuery.error instanceof AxiosError && assignmentSpacesQuery.error.response?.status == 404) ||
        !assignmentSpacesQuery.data
    ) {
        return (
            <AssignmentCardWrapper>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('spaces:not_found')} />
            </AssignmentCardWrapper>
        )
    }

    if (assignmentSpacesQuery.error) {
        return (
            <AssignmentCardWrapper>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:not_found')} />
            </AssignmentCardWrapper>
        )
    }

    return (
        <AssignmentCardWrapper>
            <Flexbox direction="column" gap={1}>
                <AssignmentCardTitle>{t('assignment:shootlist_card:title')}</AssignmentCardTitle>
                {assignmentSpacesQuery.data &&
                    assignmentSpacesQuery.data.length > 0 &&
                    assignment.task_category.name == 'Luxe' && (
                        <AssignmentCardSubtitle>{t('assignment:shootlist_card:subtitle')}</AssignmentCardSubtitle>
                    )}
            </Flexbox>

            {shouldShowShootlistCard && <>
                {assignmentSpacesQuery.data && assignmentSpacesQuery.data.length > 0 ? (
                <StyledAssignmentShootlist render="ul" gap={4.5} direction="column">
                    {assignmentSpacesQuery.data.map(assignmentSpace => (
                        <StyledAssignmentShootlistItem key={assignmentSpace.id} gap={4} align="center" render="li">
                            <Flexbox>
                                <Checkbox
                                    id={assignmentSpace.id.toString()}
                                    containerClass="assignment-shootlist-checkbox"
                                    label={t(spaceKeyToLabel[assignmentSpace.space.key_name].label, {
                                        value: spaceKeyToLabel[assignmentSpace.space.key_name]?.value
                                    })}
                                    onChange={() => onChangeShootlistFlag(assignmentSpace.space.key_name)}
                                    checked={currentFlags.includes(assignmentSpace.space.key_name)}
                                    // Visible only on mobile:
                                    helpText={t('assignment:shootlist_card:minimum_images', {
                                        count: assignmentSpace.configuration_space.raws_minimum_images
                                    })}
                                />
                            </Flexbox>

                            <StyledDashedDivider />

                            <StyledPhotosCounter>
                                {t('assignment:shootlist_card:minimum_images', {
                                    count: assignmentSpace.configuration_space.raws_minimum_images
                                })}
                            </StyledPhotosCounter>
                        </StyledAssignmentShootlistItem>
                    ))}
                </StyledAssignmentShootlist>
                ) : (
                    <p>{t('assignment:shootlist_card:empty')}</p>       
                )}
            </>}

            {assignment?.pdf_link && (
                <StyledAssignmentShootlistLink>
                    <Trans
                        i18nKey={
                            assignment.task_category.name == 'Host'
                                ? t('assignment:shootlist_card:link_host')
                                : t('assignment:shootlist_card:link', {category: getAssignmentServiceType(assignment)})
                        }
                        components={{
                            a: <a href={assignment.pdf_link} target="_blank" />
                        }}
                    />
                </StyledAssignmentShootlistLink>
            )}
        </AssignmentCardWrapper>
    )
}
