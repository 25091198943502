import React from 'react'
import {useRoutes, Navigate} from 'react-router-dom'
// Layouts
import {PrivateLayout} from './containers/private-layout/PrivateLayout'
import {PublicLayout} from './containers/public-layout/PublicLayout'
import {AdminLayout} from './containers/admin-layout/AdminLayout'
// Route Guards
import {PublicRoute} from './route-guards/PublicRoute'
import {PrivateRoute} from './route-guards/PrivateRoute'
import {SharedRoute} from './route-guards/SharedRoute'
import {AdminRoute} from './route-guards/AdminRoute'
// Auth
import {ForgotPassword} from './pages/auth/forgot-password/ForgotPassword'
import {ClientLogin} from './pages/auth/client-login/ClientLogin'
import {ResetPassword} from './pages/auth/reset-password/ResetPassword'
import {CreateAccount} from './pages/auth/create-account/CreateAccount'
// Private
import {Dashboard} from './pages/private/dashboard/Dashboard'
import {FreelancerPayments} from './pages/private/payments/FreelancerPayments'
import {Settings} from './pages/private/settings/Settings'
// Admin
import {Payments as AdminPayments} from './pages/admin/payments/Payments'
import {PaymentsImport} from './pages/admin/payments/import/PaymentsImport'
import {PaymentsGenerator} from './pages/admin/payments/payments-generator/PaymentsGenerator'
import {Matterport} from './pages/admin/matterport/tables/MatterportTables'
import {Uploader} from './pages/admin/matterport/uploader/Uploader'
import {Task} from './pages/admin/matterport/task/Task'
import {AdminFreelancers} from './pages/admin/freelencers/Freelancers'
import {AdminLogin} from './pages/admin/login/Login'
import {AdminLogout} from './pages/admin/logout/Logout'
import {AdminAssignments} from './pages/admin/assignments/Assignments'
import {AdminHostApprovalTask} from './pages/admin-host-approval-task/AdminHostApprovalTask.tsx'
// Shared
import {PageNotFound} from './pages/shared/page-not-found/PageNotFound'
// Constants
import {ROUTES} from './helpers/constants'
import {Assignment} from './pages/assignment/Assignment'
import {AdminHostApproval} from './pages/admin-host-approval/AdminHostApproval'
import {AdminHostApprovalNew} from './pages/admin-host-approval-new/AdminHostApprovalNew'
import {AdminHostApprovalPendingHostApproval} from './pages/admin-host-approval-pending-host-approval/AdminHostApprovalPendingHostApproval'
import {AdminHostApprovalReadyForReview} from './pages/admin-host-approval-ready-for-review/AdminHostApprovalReadyForReview'
import {AdminHostApprovalCompleted} from './pages/admin-host-approval-completed/AdminHostApprovalCompleted'
import {AdminHosts} from './pages/admin-hosts/AdminHosts'

export const AppRoutes = () =>
    useRoutes([
        {
            element: <AdminRoute />,
            children: [
                {
                    element: <AdminLayout />,
                    children: [
                        {
                            exact: true,
                            path: ROUTES.ADMIN,
                            element: <AdminAssignments />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_ASSIGNMENTS,
                            element: <AdminAssignments />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_PARTNERS,
                            element: <AdminFreelancers />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_LOGOUT,
                            element: <AdminLogout />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_MATTERPORT,
                            element: <Matterport />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_MATTERPORT_UPLOADER,
                            element: <Uploader />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_MATTERPORT_TASK,
                            element: <Task />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_HOSTS,
                            element: <AdminHosts />
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_PAYMENTS,
                            element: <AdminPayments />,
                            children: [
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_PAYMENTS_PROCESS,
                                    element: <PaymentsImport />
                                },
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_PAYMENTS_GENERATE,
                                    element: <PaymentsGenerator />
                                }
                            ]
                        }
                    ]
                },
                {
                    element: <AdminLayout background="white" />,
                    children: [
                        // {
                        //     exact: true,
                        //     path: ROUTES.ADMIN_SERVICE_VETTING,
                        //     element: <AdminServiceVetting />
                        // },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_HOST_APPROVAL,
                            element: <AdminHostApproval />,
                            children: [
                                {
                                    index: true,
                                    element: <Navigate to={ROUTES.ADMIN_HOST_APPROVAL_NEW} replace />
                                },
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_HOST_APPROVAL_NEW,
                                    element: <AdminHostApprovalNew />
                                },
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_HOST_APPROVAL_PENDING_HOST_APPROVAL,
                                    element: <AdminHostApprovalPendingHostApproval />
                                },
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_HOST_APPROVAL_READY_FOR_REVIEW,
                                    element: <AdminHostApprovalReadyForReview />
                                },
                                {
                                    exact: true,
                                    path: ROUTES.ADMIN_HOST_APPROVAL_COMPLETED,
                                    element: <AdminHostApprovalCompleted />
                                }
                            ]
                        },
                        {
                            exact: true,
                            path: ROUTES.ADMIN_HOST_APPROVAL_TASK,
                            element: <AdminHostApprovalTask />
                        }
                    ]
                }
            ]
        },
        {
            element: <PrivateRoute />,
            children: [
                {
                    element: <PrivateLayout />,
                    children: [
                        {
                            exact: true,
                            path: ROUTES.INDEX,
                            element: <Dashboard />
                        },
                        {
                            exact: true,
                            path: ROUTES.HOME,
                            element: <Dashboard />
                        },
                        {
                            exact: true,
                            path: ROUTES.TRANSACTION_HISTORY,
                            element: <FreelancerPayments />
                        },
                        {
                            exact: true,
                            path: ROUTES.SINGLE_ASSIGNMENT,
                            element: <Assignment />
                        },
                        {
                            exact: true,
                            path: ROUTES.SETTINGS,
                            element: <Settings />
                        }
                    ]
                }
            ]
        },
        {
            element: <PublicRoute />,
            children: [
                {
                    element: <PublicLayout />,
                    children: [
                        {
                            exact: true,
                            path: ROUTES.ADMIN_LOGIN,
                            element: <AdminLogin />
                        },
                        {exact: true, path: ROUTES.LOGIN, element: <ClientLogin />},
                        {
                            exact: true,
                            path: ROUTES.FORGOT_PASSWORD,
                            element: <ForgotPassword />
                        },
                        {
                            exact: true,
                            path: ROUTES.RESET_PASSWORD,
                            element: <ResetPassword />
                        },
                        {
                            exact: true,
                            path: ROUTES.CREATE_USER,
                            element: <CreateAccount />
                        }
                    ]
                }
            ]
        },
        {
            element: <SharedRoute />,
            children: [
                {exact: true, path: '*', element: <PageNotFound />},
                {
                    exact: true,
                    path: '/',
                    element: <Navigate to={ROUTES.INDEX} />
                }
            ]
        }
    ])
