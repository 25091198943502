import {ClockIcon, Grid01Icon, Hash02Icon, Home02Icon, MarkerPin01Icon, User01Icon} from 'src/components/icon'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {
    StyledAssignmentDataItem,
    StyledAssignmentDataItemList,
    StyledAssignmentDataTitle
} from 'src/features/assignment/components/assignment-data/style'
import {Assignment} from 'src/features/assignment/types.ts'
import {useTranslation} from 'react-i18next'
import { useMemo } from 'react'
import { getAssignmentServiceType } from '../../utils'

export const AssignmentData = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()

    const renderAssignmentServiceType = useMemo(() => {
       return getAssignmentServiceType(assignment)
    }, [assignment.task_category.name, assignment.task_media.name])

    return (
        <Flexbox gap={4} direction="column">
            <StyledAssignmentDataTitle>
                {assignment.entity.title ?? t('commons:listing_n_x', {externalId: assignment.entity.external_id})}
            </StyledAssignmentDataTitle>
            <StyledAssignmentDataItemList>
                <StyledAssignmentDataItem $isCapitalized>
                    <Grid01Icon />
                   {renderAssignmentServiceType} 
                </StyledAssignmentDataItem>
                <StyledAssignmentDataItem>
                    <Hash02Icon />
                    <a href={assignment.airbnb_link} target="_blank">
                        {assignment.entity.external_id}
                    </a>
                </StyledAssignmentDataItem>
                <StyledAssignmentDataItem>
                    <MarkerPin01Icon />
                    {!!assignment.entity.address && (
                        <a
                            href={`https://www.google.com/maps/place/${assignment.entity.address}%20${
                                assignment.entity.city ?? ''
                            }`}
                            target="_blank"
                        >
                            {assignment.entity.address}
                        </a>
                    )}
                </StyledAssignmentDataItem>
                {!!assignment.entity.bedrooms && (
                    <StyledAssignmentDataItem>
                        <Home02Icon /> {t('assignment:x_bedrooms', {count: assignment.entity.bedrooms})}
                    </StyledAssignmentDataItem>
                )}
                {!!assignment.entity.experience_duration && (
                    <StyledAssignmentDataItem>
                        <ClockIcon /> {t('assignment:x_hours', {count: assignment.entity.experience_duration})}
                    </StyledAssignmentDataItem>
                )}
                <StyledAssignmentDataItem>
                    <User01Icon />
                    {t('assignment:host_pricing_tier', {
                        value: assignment.quote.amount ? t('assignment:paid') : t('commons:free')
                    })}
                </StyledAssignmentDataItem>
            </StyledAssignmentDataItemList>
        </Flexbox>
    )
}
