import {Trans, useTranslation} from 'react-i18next'
import {StyledCompensationTotalInfo} from 'src/features/assignment/components/assignment-compensation-card/style'
import {Divider} from 'src/components/divider/Divider'
import {
    AssignmentCardTitle,
    AssignmentCardWrapper
} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {AssignmentCompensationRow} from 'src/features/assignment/components/assignment-compensation-card/AssignmentCompensationRow'
import {FC} from 'react'
import {Assignment} from 'src/features/assignment/types'
import {getServiceType} from 'src/features/assignment/utils.tsx'

interface AssignmentCompensationCardProps {
    assignment: Assignment
}

export const AssignmentCompensationCard: FC<AssignmentCompensationCardProps> = ({assignment}) => {
    const {t} = useTranslation()
    const serviceType = getServiceType(assignment.task_media.name, assignment.task_category.name)

    return (
        <AssignmentCardWrapper>
            <AssignmentCardTitle>{t('assignment:compensation_card:title', {currency: 'USD'})}</AssignmentCardTitle>

            <AssignmentCompensationRow
                amount={assignment.amount}
                compensationKey={serviceType == 'walkthrough' ? 'walkthrough' : 'photo_session'}
                currency="USD"
            />

            {!!assignment.travel_compensation && (
                <AssignmentCompensationRow
                    amount={assignment.travel_compensation}
                    compensationKey="travel"
                    currency="USD"
                />
            )}

            {!!assignment.portrait_bonus_amount && (
                <AssignmentCompensationRow
                    amount={assignment.portrait_bonus_amount}
                    compensationKey="profile_photo"
                    currency="USD"
                />
            )}

            {!!assignment.bonus_amount && (
                <AssignmentCompensationRow
                    amount={assignment.bonus_amount}
                    compensationKey="bonus"
                    currency="USD"
                    tooltip={<Trans i18nKey="assignment:compensation_card:bonus_tooltip" components={{b: <b />}} />}
                />
            )}

            <Divider direction="horizontal" />

            <AssignmentCompensationRow
                amount={assignment.total_amount || 0}
                compensationKey="total"
                currency="USD"
                size="md"
                isBoldAmount
            />

            {!!assignment.bonus_amount && (
                <StyledCompensationTotalInfo>
                    <Trans
                        i18nKey="assignment:compensation_card:total_compensation_info"
                        components={{b: <b />}}
                        values={{amount: assignment.bonus_amount}}
                    />
                </StyledCompensationTotalInfo>
            )}
        </AssignmentCardWrapper>
    )
}
